@use '@/styles/utils/mixins.scss' as *;

.documentsListingWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  width: 100%;

  .searchWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.25rem;
    position: relative;
    max-width: 61.625rem;

    @include mob() {
      width: calc(100% + 2.5rem);
      margin: 0 -1.25rem;
    }

    .searchContainer {
      width: 100%;
      display: flex;
      gap: 1.25rem;
    }

    .searchBox {
      display: flex;
      border: 0.063rem solid $neutral06;
      border-radius: 1.25rem;
      overflow: hidden;
      padding: 0.25rem 1rem;
      padding-left: 2rem;
      align-items: center;
      background-color: transparent;
      width: 100%;
      flex: 1 1 auto;
      .searchBoxInput {
        padding: 0;
        border: 0;
        height: auto;
        min-height: auto;
        font-size: 1rem;
        line-height: normal;
        color: $neutral08;
        font-weight: 500;
        outline: 0;
        background-color: transparent;
        &::placeholder {
          color: $neutral06;
          opacity: 1;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .searchBtn {
        padding: 1rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        height: 100%;
        border-radius: 1.25rem;
        transition: all 0.2s ease-in-out;
        background-color: transparent;
        &:focus {
          box-shadow: none;
        }
        &:hover,
        &:focus,
        &:active {
          background: $white !important;
        }
      }
    }

    .filterBtn {
      background-color: $neutral01;
      border: 0.063rem solid $neutral06;
      color: $dark;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.875rem 1.5rem;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      display: inline-flex;
      gap: 0.25rem;
      border-radius: 1.25rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-width: 15.625rem;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 99;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;

      @include tab() {
        padding: 0.875rem 1.25rem;
        max-width: none;
        width: max-content;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: $primaryAlt;
      }

      .btnText {
        display: inline-flex;
        @include tab() {
          display: none;
        }
      }
      .btnIcon {
        display: none;
        @include tab() {
          display: inline-flex;
        }
      }
    }

    .filterMenu {
      max-width: 15.625rem;
      width: 50%;
      border: 0.063rem solid transparent;
      border-radius: 1.25rem;
      display: flex;
      flex-direction: column;
      background-color: $neutral01;
      position: absolute;
      right: 0;
      top: 3.5rem;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition:
        height 0.5s ease-in-out,
        opacity 0.1s ease-in-out,
        width 0.1s ease-in-out;
      z-index: 2;

      @include tab() {
        position: static;
        top: auto;
        right: auto;
        max-width: 100%;
        width: 100%;
      }

      &.open {
        height: fit-content;
        opacity: 1;
        border-color: $neutral06;
        width: 100%;
      }

      .filterMenuItem {
        font-size: 1rem;
        font-weight: bold;
        color: $dark;
        padding: 1rem 1.25rem;
        transition: all 450ms ease-in-out;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }
  .documentListings {
    max-width: 61.625rem;
    :global {
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 1.5rem 0;
        color: $dark;
        line-height: 1;
        font-size: 1.25rem;
        font-weight: 500;
        cursor: pointer;
        transition:
          all 0.3s,
          visibility 0s;
        border-bottom: 0.063rem solid $primaryAlt;
      }
      .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
        padding: 1.25rem 0.75rem;
        gap: 0.75rem;
        display: flex;
        flex-direction: column;
        border-radius: 1.25rem;
        background-color: $white;
        margin: 1.875rem 0;
      }
    }
    .collapseLevel1 {
      .openBox {
        transition: all 0.2s ease-in-out;
        transform: rotate(-180deg);
      }
      .closeBox {
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
      }

      :global {
        .ant-collapse {
          border-radius: 1.25rem;
          background-color: $white;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 1rem 1.25rem;
          font-size: 1rem;
          font-weight: bold;
          border-bottom: 0;
        }
        .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
          padding: 1.25rem;
          gap: 2.125rem;
          display: flex;
          flex-direction: column;
        }
      }
    }
    .collapseLevel2 {
      .openBox {
        transition: all 0.2s ease-in-out;
        transform: rotate(90deg);
      }
      .closeBox {
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
      }
    }

    .documentItem {
      &Box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.75rem;
        width: 100%;
        border-radius: 1.25rem;
        padding: 1.25rem 1.875rem;
        background-color: $neutral02;

        @include tab() {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &Title {
        font-size: 1rem;
        font-weight: bold;
        color: $primary;
        margin-bottom: 0;
      }
      &DownloadSection {
        display: flex;
        align-items: center;
        gap: 1.25rem;
      }
      &DownloadLink {
        font-size: 1rem;
        font-weight: 500;
        color: $dark;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }
}
